import _ from 'lodash'
import { CSSProperties, useCallback, useMemo } from 'react'
import {
  DataGridPro,
  GridRowModel,
  GridRow,
  GridRowParams,
  GridRowProps,
} from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/react'
import { getObjectHash } from 'helpers/utils'
import type { TreeDataGridProps, TreeTableActions } from './types'
import { loadingMoreOverlay, loadingOverlay } from '../DataGrid'

import scss from './index.module.scss'

const buildTreePath = <T extends GridRowModel>(row: T, data: T[]): string[] => {
  if (!row) return []

  const parent = _.find(data, { id: row.parentId })
  return parent
    ? [...buildTreePath(parent, data), row.id as string]
    : [row.id as string]
}

const COLUMN_HEADER_HEIGHT = 42
const ROW_HEIGHT = 44

const CustomRow =
  (itemActions: TreeTableActions) =>
  ({ row, ...props }: GridRowProps) =>
    (
      <GridRow
        {...props}
        row={row}
        onMouseEnter={() => itemActions.onMouseEnter?.(row)}
        onMouseLeave={() => itemActions.onMouseLeave?.(row)}
      />
    )

const TreeDataGrid = <T extends GridRowModel>({
  itemActions = {},
  sortField,
  sortOrder,
  currentActiveItem,
  list = [],
  dataKey = 'id',
  slots,
  slotProps,
  style,
  loading,
  loadingMore,
  noMaxHeight = false,
  ...restProps
}: TreeDataGridProps<T> & {
  style?: CSSProperties
  noMaxHeight?: boolean
}): JSX.Element => {
  const getRowClassName = useCallback(
    (params: GridRowParams<T>): string => {
      if (params.id === currentActiveItem?.id) return 'Mui-selected'
      return params.row.parentId ? 'tree-child-row' : 'tree-parent-row'
    },
    [currentActiveItem]
  )

  const memoizedBuildTreePath = useCallback(
    (row: T) => buildTreePath(row, list),
    [list]
  )

  const hideFooter = useMemo(() => !_.has(slots, 'footer'), [slots])

  return (
    <div
      className={scss.container}
      style={noMaxHeight ? { ...style } : { height: '100%', ...style }}
    >
      <DataGridPro
        rows={list}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        disableRowSelectionOnClick
        rowHeight={ROW_HEIGHT}
        sortingOrder={['asc', 'desc']}
        getRowClassName={getRowClassName}
        initialState={{
          sorting: {
            sortModel: [{ field: sortField, sort: sortOrder ? 'asc' : 'desc' }],
          },
        }}
        slots={{
          ...slots,
          row: CustomRow(itemActions),
        }}
        slotProps={{
          ...slotProps,
          loadingOverlay: loadingMore ? loadingMoreOverlay : loadingOverlay,
        }}
        groupingColDef={{
          width: 50,
          headerClassName: 'grouping-column-header',
          resizable: false,
        }}
        rowSelectionModel={
          currentActiveItem?.[dataKey] ? [currentActiveItem?.[dataKey]] : []
        }
        getRowId={row => row[dataKey] ?? getObjectHash(row)}
        treeData
        hideFooter={hideFooter}
        getTreeDataPath={memoizedBuildTreePath}
        loading={loading || loadingMore}
        {...restProps}
      />
    </div>
  )
}

export const TreeDataGridContainer = <T extends GridRowModel>(
  props: TreeDataGridProps<T>
) => {
  return (
    <Sentry.ErrorBoundary fallback={() => <>Something went wrong.</>}>
      <TreeDataGrid {...props} />
    </Sentry.ErrorBoundary>
  )
}

export default TreeDataGridContainer
